var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "협력사 협의체 기본정보" },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              required: "",
                              editable: _vm.editable,
                              label: "제목",
                              name: "councilTitle",
                            },
                            model: {
                              value: _vm.tabParam.councilTitle,
                              callback: function ($$v) {
                                _vm.$set(_vm.tabParam, "councilTitle", $$v)
                              },
                              expression: "tabParam.councilTitle",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                        },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              required: "",
                              editable: _vm.editable,
                              label: "일자",
                              name: "councilDate",
                            },
                            model: {
                              value: _vm.tabParam.councilDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.tabParam, "councilDate", $$v)
                              },
                              expression: "tabParam.councilDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                        },
                        [
                          _c("c-plant", {
                            attrs: {
                              required: "",
                              editable: _vm.editable,
                              type: "edit",
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.tabParam.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.tabParam, "plantCd", $$v)
                              },
                              expression: "tabParam.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                        },
                        [
                          _c("c-textarea", {
                            attrs: {
                              editable: _vm.editable,
                              rows: 4,
                              label: "내용",
                              name: "councilContents",
                            },
                            model: {
                              value: _vm.tabParam.councilContents,
                              callback: function ($$v) {
                                _vm.$set(_vm.tabParam, "councilContents", $$v)
                              },
                              expression: "tabParam.councilContents",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "첨부파일" },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-sm-12 col-md-12 col-lg-12",
                        },
                        [
                          _c("c-upload", {
                            attrs: {
                              attachInfo: _vm.attachInfo,
                              editable: _vm.editable,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "popup-bottom-bar" }, [
            _c(
              "div",
              { staticClass: "popup-bottom-bar-btngroup" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _vm.editable
                      ? _c("c-btn", {
                          attrs: {
                            flat: true,
                            url: _vm.saveUrl,
                            isSubmit: _vm.isSave,
                            param: _vm.tabParam,
                            mappingType: _vm.mappingType,
                            label: "저장",
                            icon: "save",
                          },
                          on: {
                            beforeAction: _vm.saveData,
                            btnCallback: _vm.saveCallback,
                          },
                        })
                      : _vm._e(),
                    _vm.tabParam.councilId && _vm.editable
                      ? _c("c-btn", {
                          attrs: {
                            flat: true,
                            label: "삭제",
                            editable: _vm.editable,
                            icon: "delete_forever",
                          },
                          on: { btnClicked: _vm.removeData },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "popup-bottom-bar-close" },
              [
                _c("q-btn", {
                  attrs: { flat: "", color: "gray", icon: "arrow_back" },
                  on: { click: _vm.closePopUps },
                }),
              ],
              1
            ),
          ]),
          _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }